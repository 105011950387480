import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'locales/i18n';
import { FloatingButtons } from 'components/FloatingButtons';

// Vercel Analytics
import { Analytics } from "@vercel/analytics/react"

// Declare Tawk_API with any type 
declare var Tawk_API: any;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const Index = () => {
    return <App />;
};

root.render(
    <React.StrictMode>
        <Analytics />
        <Index />
        <FloatingButtons
            whatsappLink="https://wa.me/+972532200721"
            telegramLink="https://t.me/royal_casino_il"
        />
    </React.StrictMode>
);

reportWebVitals();
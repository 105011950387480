// banner
import Slide1 from 'assets/img/slide/01.png';
import Slide2 from 'assets/img/slide/02.png';
import Slide3 from 'assets/img/slide/03.png';

// menu
import LiveCasino from 'assets/img/menu/LiveCasino.png';
import FIFA from 'assets/img/menu/FIFA.png';
import Tennis from 'assets/img/menu/Tennis.png';
import Basketball from 'assets/img/menu/Basketball.png';
import Slots from 'assets/img/menu/Slots.png';
import Live from 'assets/img/menu/live.png';
import Soccer from 'assets/img/menu/Soccer.png';
import NBA from 'assets/img/menu/NBA.png';
import Baseball from 'assets/img/menu/Baseball.png';
import AmericanFootBall from 'assets/img/menu/American FootBall.png';
import IceHockey from 'assets/img/menu/Ice Hockey.png';
import MMA from 'assets/img/menu/MMA.png';
import Boxing from 'assets/img/menu/Boxing.png';
import Volleyball from 'assets/img/menu/VolleyBall.png';
import Snooker from 'assets/img/menu/Snooker.png';
import Darts from 'assets/img/menu/Darts.png';
import TableTennis from 'assets/img/menu/Table Tennis.png';
import Formula from 'assets/img/menu/Formula 1.png';
import Cricket from 'assets/img/menu/Cricket.png';
import Rugby from 'assets/img/menu/Rugby.png';

// payments
import MuchbetterIcon from 'assets/img/payments/muchbetter.png';
import ZimplerIcon from 'assets/img/payments/zimpler-2022.png';
import RapidtransferIcon from 'assets/img/payments/rapidtransfer.png';
import EcopayzIcon from 'assets/img/payments/ecopayz.png';
import AstropayCardIcon from 'assets/img/payments/astropay-card.png';
import GiropayIcon from 'assets/img/payments/giropay.png';
import PaysafecardIcon from 'assets/img/payments/paysafecard.png';
import VisamastercIcon from 'assets/img/payments/visamasterc.png';
import EvoucherGreyIcon from 'assets/img/payments/evoucher-grey.png';
import EutellerIcon from 'assets/img/payments/euteller.png';
import SkrillIcon from 'assets/img/payments/skrill.png';
import NetellerIcon from 'assets/img/payments/neteller.png';

const NODE_ENV = process.env.NODE_ENV;

export const HOST_API_KEY =
    NODE_ENV === 'production' ? process.env.REACT_APP_HOST_API : process.env.REACT_APP_LOCAL_HOST_API;

export const PATH_AFTER_LOGIN = '/auth/login';
export const TOP_NAV_HEIGHT = 64;
export const SIDE_NAV_WIDTH = 260;
export const SIDE_MINI_NAV_WIDTH = 64;
export const CHAT_BOX_WIDTH = 360;

export const BANNER_LIST = [Slide1, Slide2, Slide3];

export const PAYMENT_LIST = [
    MuchbetterIcon,
    ZimplerIcon,
    RapidtransferIcon,
    EcopayzIcon,
    AstropayCardIcon,
    GiropayIcon,
    PaysafecardIcon,
    VisamastercIcon,
    EvoucherGreyIcon,
    EutellerIcon,
    SkrillIcon,
    NetellerIcon
];

export const LOCAL_SPORT_KEY = 'BETIM_SPORT_TOKEN';

export const PageNationPerPage = [10, 25, 50, 100];

export const LIVE_PROVIDER_LIST = [
    {
        Id: 0,
        Name: 'All',
        Count: 0
    },
    {
        Id: 38,
        Name: 'SaGaming',
        Count: 0
    },
    {
        Id: 17,
        Name: 'Evolution',
        Count: 0
    },
    {
        Id: 125,
        Name: 'EvolutionEzugi',
        Count: 0
    },
    {
        Id: 124,
        Name: 'Lucky',
        Count: 0
    },
    {
        Id: 14,
        Name: 'Vivo',
        Count: 0
    }
];

export const VENDER_LIST = [
    {
        Id: 0,
        Name: 'All',
        Count: 0
    },
    {
        Id: 120,
        Name: 'BetSoft',
        Count: 398
    },
    {
        Id: 35,
        Name: 'bGaming',
        Count: 282
    },
    {
        Id: 119,
        Name: 'Funky Games',
        Count: 52
    },
    {
        Id: 96,
        Name: 'Inbet Games',
        Count: 398
    },
    {
        Id: 116,
        Name: 'KA Gaming',
        Count: 912
    },
    {
        Id: 122,
        Name: 'Mr.Slotty',
        Count: 112
    },
    {
        Id: 123,
        Name: 'NetEnt',
        Count: 334
    },
    {
        Id: 121,
        Name: 'SmartSoft Gaming',
        Count: 106
    }
];

export const MENU_LIST = [
    {
        order: 0,
        icon: LiveCasino,
        name: 'Live Casino',
        link: 'live-casino'
    },
    {
        order: 1,
        icon: Slots,
        name: 'Slots',
        link: 'slots'
    },
    {
        order: 2,
        icon: Live,
        name: 'Live',
        param: 'live'
    },
    {
        order: 3,
        icon: Soccer,
        name: 'Soccer',
        param: 'soccer-1',
        divide: true
    },
    {
        order: 4,
        icon: Basketball,
        name: 'Basketball',
        param: 'basketball-2'
    },
    {
        order: 5,
        icon: Tennis,
        name: 'Tennis',
        param: 'tennis-5'
    },
    {
        order: 6,
        icon: FIFA,
        name: 'FIFA',
        param: 'fifa-300'
    },
    {
        order: 7,
        icon: NBA,
        name: 'NBA 2K',
        param: 'nba-2k-302'
    },
    {
        order: 8,
        icon: Baseball,
        name: 'Baseball',
        param: 'baseball-3'
    },
    {
        order: 9,
        icon: AmericanFootBall,
        name: 'FootBall',
        param: 'american-football-16'
    },
    {
        order: 10,
        icon: IceHockey,
        name: 'Ice Hockey',
        param: 'ice-hockey-4'
    },
    {
        order: 11,
        icon: MMA,
        name: 'MMA',
        param: 'mma-117'
    },
    {
        order: 12,
        icon: Boxing,
        name: 'Boxing',
        param: 'boxing-10'
    },
    {
        order: 13,
        icon: Volleyball,
        name: 'Volleyball',
        param: 'volleyball-23'
    },
    {
        order: 14,
        icon: Snooker,
        name: 'Snooker',
        param: 'snooker-19'
    },
    {
        order: 15,
        icon: Darts,
        name: 'Darts',
        param: 'darts-22'
    },
    {
        order: 16,
        icon: TableTennis,
        name: 'Table Tennis',
        param: 'table-tennis-20'
    },
    {
        order: 17,
        icon: Formula,
        name: 'Formula 1',
        param: 'formula-1-40'
    },
    {
        order: 18,
        icon: Cricket,
        name: 'Cricket',
        param: 'cricket-21'
    },
    {
        order: 19,
        icon: Rugby,
        name: 'Rugby',
        param: 'rugby-12'
    }
];

export const CASINO_LIST = [
    {
        GameID: 26885,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'BlackjackVIP2',
        Name: 'BlackjackVIP2',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/cd6e9b81187e763995679a0c32e0a87a3ae7e929/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26886,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Roulette-3',
        Name: 'Roulette 3',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/4ac6a332d5526bc93a16605f4a9bb8c2ca8c3072/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26887,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Autoroulette-2',
        Name: 'Autoroulette 2',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/afb1c717b3f848325ef617e93db67cd0505eefb3/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26888,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'X-Baccarat-M2',
        Name: 'X Baccarat M2',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/6bd809e484d19c5891d4c078da2192887b704d7a/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26889,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'X-Baccarat-M3',
        Name: 'X Baccarat M3',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ded278cfc9139d58bbac7bbc1b536d03e4f84905/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26890,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'X-Baccarat-M4',
        Name: 'X Baccarat M4',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/52285fa85df3a1b0ef5cc4c33bd5bbb04e764640/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26891,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'X-Baccarat-M1',
        Name: 'X Baccarat M1',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/45df2e66992e11639998cfd2ded8011c9cfa9222/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26892,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-7',
        Name: 'Blackjack 7',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/6b034fcb8adbdb07b83eb8da57e623c95c06ad5e/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26893,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-16',
        Name: 'Blackjack 16',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/0c248b4f1857de93c834b27e0451ec5921ac64b5/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26894,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-6',
        Name: 'Blackjack 6',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/7ee73277f607dbfaca8c37d9777f795565ee2bcf/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26895,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Oracle-360',
        Name: 'Oracle 360',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/81c7bc9e1a8e3d50f2b4559171e2b12bc5e21c31/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26896,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Portomaso-Oracle-Roulette-1',
        Name: 'Portomaso Oracle Roulette 1',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/81897f52b7ce2a791cda511c8f3ebfa269790c31/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26897,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Portomaso-Casino-Roulette-2',
        Name: 'Portomaso Casino Roulette 2',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/6c2181d216472f9af786db1d0f19635a0f912b33/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26898,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Fashion-Roulette',
        Name: 'Fashion Roulette',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/8ffead66e2d6680d1a2e0a116b33b5277cc7caab/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26899,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'AutoRoulette-1',
        Name: 'AutoRoulette 1',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e72a6662c08d8da0615d7bbdb4866ae93384e437/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26900,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-3',
        Name: 'Blackjack 3',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/c241640156589d2a680065283e43bc3a3a22903c/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26901,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP',
        Name: 'Blackjack VIP',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/8a5e7b2be5f1cfd1bf1d820f421c9ece115744e3/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26902,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-1',
        Name: 'Blackjack 1',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/0976e0e8dd8f963fde78df1e965360976c38e246/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26903,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Roulette-1',
        Name: 'Roulette 1',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/dd768a556597260eac2bb4f87d2ec42a46cbe22f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26904,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Baccarat-1',
        Name: 'Baccarat 1',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/83ab587561ee2bb471ef530325e1cca787a68834/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26905,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Lucky-Streak-Main-Lobby',
        Name: 'Lucky Streak Main Lobby',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/b137698879b7b0caf81653fe67e6c51f1e28cbab/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26906,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Baccarat-Lobby',
        Name: 'Baccarat Lobby',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/387db244d01c124ffb1c2293bc1ef96be32b26ae/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26907,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-Lobby',
        Name: 'Blackjack Lobby',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/77b91318af3a145241fd5a5aeb6316e6b222a493/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26908,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Roulette-Lobby',
        Name: 'Roulette Lobby',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/87ecc7a314104fdbdebcce19708e977c2dced5ee/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26909,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'AutoRoulette-Lobby',
        Name: 'AutoRoulette Lobby',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/44a853dc815d805f0928a486108bd1096383d9fa/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26910,
        ProviderId: 22,
        VendorId: 124,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'ExternalRoulette-lobby',
        Name: 'ExternalRoulette lobby',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/742e9a582907495563abe695ca753565c7d745fd/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26911,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Russian-Poker',
        Name: 'Russian Poker',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/34feb532022ebbb48a8df569b35fbc4e1ca5bfaa/Games Catalog image/image_1702560725991.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26912,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: '-Teen-Patti-3-Card',
        Name: ' Teen Patti 3 Card',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/3cf7a7c013438c3513e54367a3f308ada7f033f9/Games Catalog image/image_1698927856240.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26913,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Salon-Prive-Blackjack',
        Name: 'Salon Prive Blackjack',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/2400e430a752eedb2a5d22caac04cb419b01851d/Games Catalog image/image_1710324771651.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26914,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Russian-Roulette',
        Name: 'Russian Roulette',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d2153d068d41678af60ed68079a82ea3348c6932/Games Catalog image/image_1698928490950.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26915,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'VIP-No-Commission-Speed-Cricket-Baccarat',
        Name: 'VIP No Commission Speed Cricket Baccarat',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d358ad8b7296bae49a0ee45868419857ae52ca7b/Games Catalog image/image_1710324815459.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26916,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'EZ-Dealer-Roulette-Japanese',
        Name: 'EZ Dealer Roulette Japanese',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/908944bb58ae08a0d192a9bb66f1f926e7c9b87a/Games Catalog image/image_1710324862784.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26917,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'EZ-Dealer-Roulette-Mandarin',
        Name: 'EZ Dealer Roulette Mandarin',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/1005b9a8007d07fe64620302c76b1cd295f5b711/Games Catalog image/image_1710324936557.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26918,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Sic-Bo',
        Name: 'Sic Bo',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/deb7fe3ca1a488a1ae1e2d0df9aad28ef8804042/Games Catalog image/image_1710325054514.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26919,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Italian-Roulette',
        Name: 'Italian Roulette',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/c0532bdf5495a734ee50bac22b81505e86c62125/Games Catalog image/image_1710325100329.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26920,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Ultimate-Roulette',
        Name: 'Ultimate Roulette',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/7f0a81ee7afe1d303b09e648310bf21f2d3afe66/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26921,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'EZ-Dealer-Roulette-Thai',
        Name: 'EZ Dealer Roulette Thai',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/5916a1d6ac3eedee05ceb081793c546301a2d52f/Games Catalog image/image_1710325553502.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26922,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Ultimate-Sic-Bo',
        Name: 'Ultimate Sic Bo',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/c22054cd26c1f7eae67166f5d3443f7392337d08/Games Catalog image/image_1710325304976.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26923,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Ultimate-Andar-Bahar',
        Name: 'Ultimate Andar Bahar',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/36fc064dc660a52f72c7d1e68c33fb87c0de6c2e/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26924,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'VIP-Diamond-Blackjack',
        Name: 'VIP Diamond Blackjack',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/2e7f609ee4fc0e545fffad9747c81170355f7c09/Games Catalog image/image_1710325333726.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26925,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-Fortune-Baccarat',
        Name: 'Speed Fortune Baccarat',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/109586ba492edaff7a3dfdd09f6713f65d2aae5e/Games Catalog image/image_1710325375773.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26926,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Rumba-Blakckjack-4',
        Name: 'Rumba Blakckjack 4',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/b2fd6748c800e4467605cfa892919c71a39f0ead/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26927,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Unlimited-Turkish-Blackjack',
        Name: 'Unlimited Turkish Blackjack',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/5dca2acf1dbb79fbc1333d20c4deec6266c3377b/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26928,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'One-Day-Teen-Patti-classic',
        Name: 'One Day Teen Patti classic',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/b42d2ba76428bcdc2779ca570f4de56d39ed8ea1/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26929,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Cricket-War',
        Name: 'Cricket War',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/a2709300ce198fc822d633d1775c6c4c2b06f4a3/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26930,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Fortune-Baccarat',
        Name: 'Fortune Baccarat',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/72424dccf5ba0497b1f7fe249c084484e735f6f5/Games Catalog image/image_1699347239726.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26931,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'VIP-Fortune-Baccarat',
        Name: 'VIP Fortune Baccarat',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/8399119b53170354210fa67e0620f433452462ae/Games Catalog image/image_1699347419896.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26932,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'White-Russian-Blackjack',
        Name: 'White Russian Blackjack',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/0099a7177ed14dca671fa25413f9ed74f207adf7/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26933,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Turkce-Blackjack-2',
        Name: 'Turkce Blackjack 2',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/b08e426e3ba60376f013d8233cafef0e13fffb09/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26934,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Rumba-Blackjack-1',
        Name: 'Rumba Blackjack 1',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e59a295b15691e4520ab3726b6292299a6b08658/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26935,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Rumba-Blackjack-2',
        Name: 'Rumba Blackjack 2',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d1bb067549476e1c7e6ec2a3988539d62608dcda/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26936,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Mambo-Unlimited-Blackjack',
        Name: 'Mambo Unlimited Blackjack',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/fb03df00f61684e719e9f3bfc39447f2cdbcd916/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26937,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Black-Russian-Blackjack',
        Name: 'Black Russian Blackjack',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/5b7c87fdaab68f451d474dd105e155d3f2157f33/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26938,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Rumba-Blackjack-3',
        Name: 'Rumba Blackjack 3',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/a7b3c41d266808ea7b744eed2dc531084c917ca3/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26939,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Marina-Casino-Baccarat-2',
        Name: 'Marina Casino Baccarat 2',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/eafad7cec55bde2eec60e407fa77e90f51f0e393/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26940,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-Gold-1',
        Name: 'Blackjack Gold 1',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/25d410df7d8e2e9c3546eeacfb053c9e13e26bef/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26941,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Turkce-Blackjack',
        Name: 'Turkce Blackjack',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/621513e41447bc23f4a9bd806587aa98307cb49b/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26942,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Portomaso-Real-Casino-Roulette',
        Name: 'Portomaso Real Casino Roulette',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/f4d5d6f79e8169faf8dc5a9016784438f779b148/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26943,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Italian-Blackjack',
        Name: 'Italian Blackjack',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/c2a136a964ce17365c93dbea56424866f4d3ae11/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26944,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Ruleta-Del-Sol-OTT',
        Name: 'Ruleta Del Sol - OTT',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/739d9afff25b0d54a1bcd85fbc3aaaf2cc0a0b45/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26945,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Casino-Marina-Roulette-2',
        Name: 'Casino Marina Roulette 2',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/0591b8041e52d3350f089126aca92af8c1313b86/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26946,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Casino-Marina-Baccarat-3',
        Name: 'Casino Marina Baccarat 3',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/cffb7a679296090afe2cd46639fca3d65d5b7fd4/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26947,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Casino-Marina-Baccarat-4',
        Name: 'Casino Marina Baccarat 4',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/405a8818034ea9f377011b22f06ec1b9e16c16ab/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26948,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'OTT-Andar-Bahar',
        Name: 'OTT Andar Bahar',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/8b41dbf19e453d57a8cc3d9674ecadabd458e0e7/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26949,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: '32-cards',
        Name: '32 cards',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/a8be4d33faf0eaebd9907d1f203053f1667b7ba8/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26950,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Lucky-7',
        Name: 'Lucky 7',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/573f1378df30ebf26c61e5db3894aac1de13eb92/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26951,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Namaste-Roulette',
        Name: 'Namaste Roulette',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ce7b065503a147feeb6cec9f8c8229fa7d34e992/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26952,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Bet-on-Teen-Patti',
        Name: 'Bet on Teen Patti',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/4f03b4d1b3dffed90df552e2352fbea1faaee3cd/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26953,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-1',
        Name: 'Blackjack 1',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/afdf4d174cd748e4d5b5e20b1a5886c01b90deba/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26954,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Golden-Baccarat',
        Name: 'Golden Baccarat',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/3982cfd24303ca204d65ba768d12b5892cc4ef44/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26955,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Roulette-Gold',
        Name: 'Roulette Gold',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/de45314f77b8f7a83b0cde8a84d99b4ec6fcf664/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26956,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Automatic-Roulette',
        Name: 'Automatic Roulette',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/4f22e695eca99569bcf9e485902f6be3ac1c832d/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26957,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Unlimited-Blackjack',
        Name: 'Unlimited Blackjack',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e329b766255a68995f43ef03d4d5cddb22a3446d/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26958,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Baccarat-Knock-Out',
        Name: 'Baccarat Knock Out',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/04530b609c7de69e0e63721187d5ecba68839083/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26959,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Baccarat-Super-6',
        Name: 'Baccarat Super 6',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/7578bf8e775869defb95729b223ebe07d6da04a1/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26960,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Dragon-Tiger',
        Name: 'Dragon Tiger',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/7e66742eb7ae0f25d3e79d147efa22c3e0c359b1/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26961,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Marina-Casino-Baccarat-1',
        Name: 'Marina Casino Baccarat 1',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/b250d111b02293bec0a19c90e859db3ea0ceb51d/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26962,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Portomaso-Casino-Roulette',
        Name: 'Portomaso Casino Roulette',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/34e1c3ec6b94117fa09393ec09296eb8ff875875/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26963,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Andar-Bahar',
        Name: 'Andar Bahar',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d026c18abe2c5bd74157d5b4e70d74c4da34d4d9/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26964,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Ezugi-Lobby',
        Name: 'Ezugi Lobby',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/40756e4edff665bab18c1245b7c3ddbdd92870a8/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26965,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Baccarat-No-Comission',
        Name: 'Baccarat No Comission',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/f64eefe875a2c4b89e98307a79f404135111d7d5/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26966,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-Roulette',
        Name: 'Speed Roulette',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/b1de68303dd6188a0b51d567a0c58e3b3a7cbcfb/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26967,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-Auto-Roulette',
        Name: 'Speed Auto Roulette',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/131a98af531df2d3e199478d56cb24ddc7e6f3c7/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26968,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Diamond-Roulette',
        Name: 'Diamond Roulette',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/1b4b7993500ed9bc6eaf07fc090d5de696daf3de/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26969,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-Cricket-Baccarat',
        Name: 'Speed Cricket Baccarat',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/1a9e37c4b4544729b1e74512cfe59b69b96cc7d2/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26970,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Roulette-Gold-3',
        Name: 'Roulette Gold 3',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/0f173b77d1d56d489535b3d626227c4a86fb12d0/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26971,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Marina-Casino-Roulette',
        Name: 'Marina Casino Roulette',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/c5a5dd80cb0e9ab4fdbbef89f7490cbfafc78a70/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26972,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Oracle-Casino-Roulette-360',
        Name: 'Oracle Casino Roulette 360',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/a29c21db69acd00fcc28668991ea97673a169b35/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26973,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-Gold-3',
        Name: 'Blackjack Gold 3',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/f05162781dc5de06c5ffbadc81d46e0b949c300a/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26974,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-Gold-6',
        Name: 'Blackjack Gold 6',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/8445ef4fd32de681f9beaa67f552fbbf04a08e63/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26975,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-Gold-4',
        Name: 'Blackjack Gold 4',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ab06ac9a180f19cb1ae5d12334ddbaa4221fbfce/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26976,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-Gold-5',
        Name: 'Blackjack Gold 5',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d2202dcd8138dcfc5a70c5949fca9f92c09306cf/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26977,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-Platinum',
        Name: 'Blackjack Platinum',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/62ba201a86b6b93b2e1b0b09ebc8e3a0da1353a5/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26978,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'VIP-Blackjack-with-Surrender',
        Name: 'VIP Blackjack with Surrender',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/bcd438b15dd240e5281bbf7fe6a0805ad76258fc/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26979,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'VIP-Blackjack',
        Name: 'VIP Blackjack',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/6c9cc5b6ed2e496caf53f3782cf4565d69dfb0d6/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26980,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Turkish-Blackjack-1',
        Name: 'Turkish Blackjack 1',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/507a399ff3061b670ad8b8c9cb54df8a653dd14b/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26981,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Cumbia-Ruleta-1',
        Name: 'Cumbia Ruleta 1',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/dba94f2dc4ce2af2ed250909b690c87509afbb6f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26982,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Turkish-Roulette',
        Name: 'Turkish Roulette',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/7004f0f3428a9bcff7786ef7f758d32f295f1e3c/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26983,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Oracle-Casino-Roulette',
        Name: 'Oracle Casino Roulette',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/36f3a876d0ca3afa5e780ffd792d12ab90e1605a/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 26984,
        ProviderId: 22,
        VendorId: 125,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Prestige-Auto-Roulette',
        Name: 'Prestige Auto Roulette',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/5940b2d1f797eb4ceb6765cfce2587c735b8ba65/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 13161,
        ProviderId: 22,
        VendorId: 38,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Scibo',
        Name: 'Scibo',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/756d4cd63084bba47e9a20636695242a3e34c4ad/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '1',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24115,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Instant-Super-Sic-Bo',
        Name: 'Instant Super Sic Bo',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/0872faf14c3ea1b44fcf6f7c69a3f4352a75c9b6/Games Catalog image/image_1695392158306.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 13188,
        ProviderId: 22,
        VendorId: 38,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Auto-Baccarat-1',
        Name: 'Auto Baccarat 1',
        Img: 'https://hub1-cdn.bitech.xyz/img/games/Sagaming/13188.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '1',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24116,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'First-Person-Super-Sic-Bo',
        Name: 'First Person Super Sic Bo',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/8e565ff9354a7ed8b2806c8ca593f3b05daf12a0/Games Catalog image/image_1695392339815.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 13189,
        ProviderId: 22,
        VendorId: 38,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Roullete',
        Name: 'Roullete',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/03562ceb1caf2e457a85d6cd6569d67c921849de/Games Catalog image/image.jpeg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '1',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24117,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Lightning-Lotto',
        Name: 'Lightning Lotto',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/96a6b2b3a53c8c5517fd79f4737d2f4e55a4d3b0/Games Catalog image/image_1695810192618.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24118,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'First-Person-Lightning-Lotto',
        Name: 'First Person Lightning Lotto',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/c18a73b2be54625dd23dfb923ae178f91f1b405c/Games Catalog image/image_1695810694643.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24119,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Gonzo-s-Treasure-Map',
        Name: 'Gonzo’s Treasure Map',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/8ffb79a2c29380186c0334b8d42e068631f6810c/Games Catalog image/image_1695629376951.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 13197,
        ProviderId: 22,
        VendorId: 38,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Pok-Deng',
        Name: 'Pok Deng',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ceea6ab8c59d8edd389c37efb861852a7387319f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '1',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24120,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'turkish-lightning-Rulet',
        Name: 'turkish lightning Rulet',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/be7954085b8a18037cf7d473051b6179e29fd238/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24121,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'XXXtreme-Lightning-Roulette',
        Name: 'XXXtreme Lightning Roulette',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/800bceb9d1b0d45cd70225a2919fa5da4908f141/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24122,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Auto-Rulet',
        Name: 'Auto Rulet',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/1e5082dbac839ac5744b3d42aa3dc4208644c915/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 13203,
        ProviderId: 22,
        VendorId: 38,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Pok-Deng',
        Name: 'Pok Deng',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/324a5896dd07021305d0389dd6130fcc82333937/Games Catalog image/image_1702896585990.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '1',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24123,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Monopoly-Big-baller',
        Name: 'Monopoly Big baller',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/eca7acd6da420b16814addbec9e606b33712c101/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24124,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Crazy-Coin-Flip',
        Name: 'Crazy Coin Flip',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/b640a9cbf7611d579392fdd24db309ead434ef62/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24125,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'lightning-Blackjack',
        Name: 'lightning Blackjack',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/a07c8197fcc35a1ff91c0fdeff3ba89e2d23e1cf/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24126,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Extra-Chilli-Epic-Spins-',
        Name: 'Extra Chilli Epic Spins ',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/b5432793a47bf01b896600604248e4a84aa6db32/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24127,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Dead-or-Alive-Saloon',
        Name: 'Dead or Alive: Saloon',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/c6e81be84711143fd491087e5acec0ea6cce44cf/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24128,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Red-door-roulette',
        Name: 'Red door roulette',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/3cdf99718ee2532e0df9b46665bcc340fbecb832/Games Catalog image/image_1710767094219.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24129,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Funky-Time',
        Name: 'Funky Time',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/a9b81b215166578741f3bc4effcff157474e9e5c/Games Catalog image/image_1710767360438.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24130,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Bac-Bo',
        Name: 'Bac Bo',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/3dd6e9bc72b1f3e3d2e82847ecd831135a015f16/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24131,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Fan-Tan',
        Name: 'Fan Tan',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/1bed489a2c111bf89c89a9098163b3dd8307391c/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24132,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Ruleta-Bola-Rapida-en-Vivo',
        Name: 'Ruleta Bola Rapida en Vivo',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/3eef3ce0f9bb9660ea7b0435604869c179f2f126/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24133,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'T-rk-e-Rulet',
        Name: 'Türkçe Rulet',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/318d922f42f67adefc3d3f27db634279a684adc6/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24134,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Super-Andar-Bahar',
        Name: 'Super Andar Bahar',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/8e385591849459f32455e3b8015a57c34e63d5ed/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24135,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-Baccarat-T',
        Name: 'Speed Baccarat T',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/16e2c6d70ebbf156638dea8da7673bb779f72959/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24136,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-Baccarat-U',
        Name: 'Speed Baccarat U',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/1ee32673915cf9e6ce5a22bf5100d7c10e159f77/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24137,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-Baccarat-V',
        Name: 'Speed Baccarat V',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/44769bcfed3c448953df877e10096ec8d5f46fe7/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24138,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-Baccarat-W',
        Name: 'Speed Baccarat W',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ee291ed35f2e84273720a07de4daf534c7ebb246/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24139,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-Baccarat-X',
        Name: 'Speed Baccarat X',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/3be6e7bd85cdc236c1db7956a35a8030745c16e4/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24140,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Auto-Lightning-Roulette',
        Name: 'Auto Lightning Roulette',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/f7a7725b84637c1e7dc08c9e41e92d984a5773cc/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24141,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-Fortune-VIP',
        Name: 'Blackjack Fortune VIP',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ce19cd0c45f5305eb7f180b5e0df545639a3948c/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24142,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-Grand-VIP',
        Name: 'Blackjack Grand VIP',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/67b07df8447c69e274136c8ac8726fb17a941a71/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24143,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-1',
        Name: 'Blackjack VIP 1',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/72983e3c514e8684f3aa231c792dd915624e506e/Games Catalog image/image.gif',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24144,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-10',
        Name: 'Blackjack VIP 10',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/1e5d124d6758340468fa55b63be99069f116e942/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24145,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-11',
        Name: 'Blackjack VIP 11',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/b50efd0572b4e1355e6ece3a4a231f1030d65e3d/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24146,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-12',
        Name: 'Blackjack VIP 12',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/72eaf27a9061fd30575d2dd46ea047a639695ba8/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24147,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-13',
        Name: 'Blackjack VIP 13',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/1e269cbf1ba21dd351374d4feb297b61d554663e/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24148,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-14',
        Name: 'Blackjack VIP 14',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ccdc23028dab0f82c60b93633c4b564482f7a50d/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24149,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-15',
        Name: 'Blackjack VIP 15',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e058fa8922cd345eb6a06b0c814bdf579972c231/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24150,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-16',
        Name: 'Blackjack VIP 16',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/95e58e1a0612992331f8daaa230df790f1769d02/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24151,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-17',
        Name: 'Blackjack VIP 17',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/a5bb98fd8dee0bee2e00c04887b77df170e87c2f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24152,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-18',
        Name: 'Blackjack VIP 18',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ebe8b5867a392d88814ba80e5308c203b6148d78/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24153,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-19',
        Name: 'Blackjack VIP 19',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ee5ad1377df7e5753830235bf3eb36984c7ba61d/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24154,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-2',
        Name: 'Blackjack VIP 2',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/a7b69fdcd3114f8285a440a18c434511c3abef0c/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24155,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-3',
        Name: 'Blackjack VIP 3',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/374dd109a2884b0113067cf5e792f13e2dbf916e/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24156,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-4',
        Name: 'Blackjack VIP 4',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/2b9effee9aeb68a2b436c239882e83a6d6386a01/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24157,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-5',
        Name: 'Blackjack VIP 5',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/3aa5ef86b374b1054fe9559606772f5ee654189b/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24158,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-6',
        Name: 'Blackjack VIP 6',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/2ac5f0d0a2d1f82852c07ad0ca4807ded64eb149/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24159,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-7',
        Name: 'Blackjack VIP 7',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/0093200836a110455ca9b905922d8a8bbd57fb21/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24160,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-8',
        Name: 'Blackjack VIP 8',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ea254cb2e491f7790b00639df778891342ce9ddf/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24161,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-9',
        Name: 'Blackjack VIP 9',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/05a4750f7dfb8ea929083a2952131c1b061225bf/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24162,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-F',
        Name: 'Blackjack VIP F',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/1fb18fa44e87f6959b3a6c887dc05fb76d0aa354/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24163,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-J',
        Name: 'Blackjack VIP J',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/42678a2c1db670bc277ed981d584d2eff8219964/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24164,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-K',
        Name: 'Blackjack VIP K',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/15fcc5df9cd261159ae0182cc1499f6095669539/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24165,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-N',
        Name: 'Blackjack VIP N',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/079a0d0d8165416d3663fc0afb45e5a664a43938/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24166,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-U',
        Name: 'Blackjack VIP U',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/6c7b50aa153061b3a58297601975a8b207ca9c8b/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24167,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-V',
        Name: 'Blackjack VIP V',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/4c7c98dafe34b806d96b292b6e3b75cc41046124/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24168,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-20',
        Name: 'Classic Speed Blackjack 20',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/edeed906b600b9d3286ccf135bec6423a1099a96/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24169,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-21',
        Name: 'Classic Speed Blackjack 21',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/2762ed56a325d1fcf5ab3affa821970a511141e8/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24170,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-22',
        Name: 'Classic Speed Blackjack 22',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ec3212c6de0236cf26c0cc0beb52dd10238a1634/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24171,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-23',
        Name: 'Classic Speed Blackjack 23',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e1e9229d82b7c59139ff2b71bd6f033823732409/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24172,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-24',
        Name: 'Classic Speed Blackjack 24',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/9e5d6ffd9a5d21e72474e3df96dca7428c25be64/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24173,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-25',
        Name: 'Classic Speed Blackjack 25',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d1bbe0b0bdf35cfb6afbe2806ad994fa196b960b/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24174,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-26',
        Name: 'Classic Speed Blackjack 26',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/b473fed8e1847fb42de8cd04503ae9606afadcb2/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24175,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-27',
        Name: 'Classic Speed Blackjack 27',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/589c2259019e28bc4d5dfc8e1ec3d98dc6ea1520/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24176,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-28',
        Name: 'Classic Speed Blackjack 28',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/118919e6be91709b5c29f92e7ccee64863811f61/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24177,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-29',
        Name: 'Classic Speed Blackjack 29',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/66a2acd2e7d0daf84b68b44141fe7ebd5d84edb7/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24178,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-30',
        Name: 'Classic Speed Blackjack 30',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/197c650d5704efbbffc70cda782666bedd4e1ad5/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24179,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-31',
        Name: 'Classic Speed Blackjack 31',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/55ffe0ff2e63507e9d620ea9bfedb6926d04862e/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24180,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-32',
        Name: 'Classic Speed Blackjack 32',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/fd14c36b27f631d987ae2deef2e56fc4a3310bea/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24181,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-33',
        Name: 'Classic Speed Blackjack 33',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/9b173b57e6d65f42e571a54be12c25df87063f81/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24182,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-34',
        Name: 'Classic Speed Blackjack 34',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/55052ec396d0aaf3fb27b66ec11b7e0c3ffe087a/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24183,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-35',
        Name: 'Classic Speed Blackjack 35',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/fd30cfb94829843e3a61f71588d5721f8bd5a95a/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24184,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-36',
        Name: 'Classic Speed Blackjack 36',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/f98549b1e08c1b42e5f56599f81fe1a80957733b/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24185,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-37',
        Name: 'Classic Speed Blackjack 37',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/701be847230dc83b5a0b2151e13bc03527465cf9/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24186,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-38',
        Name: 'Classic Speed Blackjack 38',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/5b2d1b8ad8c5ddae74bf772e02b23a71973a6de2/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24187,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-39',
        Name: 'Classic Speed Blackjack 39',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/5c5ea34547b91d04f8b2a1a2f10639a3f7bd5da8/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24188,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-40',
        Name: 'Classic Speed Blackjack 40',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/44d1fe893a6144be19032e971b17d2ca2bbf77b3/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24189,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-41',
        Name: 'Classic Speed Blackjack 41',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/1209f118024c7a6868b46bb24a9dfc1d926520f6/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24190,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-42',
        Name: 'Classic Speed Blackjack 42',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d29e49ab66aabdcc0a222c981045c1b8ab49c67f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24191,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-43',
        Name: 'Classic Speed Blackjack 43',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d139059e30ce2724a2085322889aae2c120721f0/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24192,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-44',
        Name: 'Classic Speed Blackjack 44',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/9209a419f8830e05433c5601973b2fc4cfdb6f6a/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24193,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-45',
        Name: 'Classic Speed Blackjack 45',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/9e1d4d19849a820d59c6ee766f639eff3b3e39a6/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24194,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-46',
        Name: 'Classic Speed Blackjack 46',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/f9ebe5dbe5b3ed8d91cf03bf56141020da788819/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24195,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-47',
        Name: 'Classic Speed Blackjack 47',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/1ba1f0b510926bfdfdfd4236a66caf5ab0457b67/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24196,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-48',
        Name: 'Classic Speed Blackjack 48',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/37fd72e316c9b15a0cf58599c684f68167abb9d7/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24197,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-49',
        Name: 'Classic Speed Blackjack 49',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/5ef87c2346d13415ccb153933b60802e163e19dc/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24198,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Classic-Speed-Blackjack-50',
        Name: 'Classic Speed Blackjack 50',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/3b10b68a67e7dbc8c165e5e1b871f9ae6f2919f7/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24199,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'First-Person-American-Roulette',
        Name: 'First Person American Roulette',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/2b3ccdfb4150627062214f0d94e2f43be864a300/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24200,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'First-Person-Baccarat',
        Name: 'First Person Baccarat',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/266989d847d1d458debd4eaec67d83c31154d65f/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24201,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'First-Person-Dragon-Tiger',
        Name: 'First Person Dragon Tiger',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/85ce8133acc533db7b4e23dffb8cebacb3a33bca/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24202,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'First-Person-Mega-Ball',
        Name: 'First Person Mega Ball',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/5ef3215378c003f5f7b2d47cc853c362ecfc0ccc/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24203,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'FIRST-PERSON-LOBBY',
        Name: 'FIRST PERSON LOBBY',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e2252e76fdb7bdf146695f59df281cd93bebbda5/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24204,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'GAME-SHOWS-LOBBY',
        Name: 'GAME SHOWS LOBBY',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/001b569664fc6581193539b0e90e77c4c564ccbb/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24205,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'No-Commission-Speed-Baccarat-A',
        Name: 'No Commission Speed Baccarat A',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d228c6440426028a12ca5e9fe3a154d76fcfc8ef/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24206,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'No-Commission-Speed-Baccarat-B',
        Name: 'No Commission Speed Baccarat B',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/2291d307738aa8a5c52e99d202bda761d492f930/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24207,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'No-Commission-Speed-Baccarat-C',
        Name: 'No Commission Speed Baccarat C',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/9ced2c0cad66e9d0b59f96f338ec205fa2c1c322/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24208,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Salon-Priv-Baccarat-D',
        Name: 'Salon Privé Baccarat D',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/b904c594a1ba4dab5aefbcf496641432e6d633d4/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24209,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Salon-Priv-Baccarat-E',
        Name: 'Salon Privé Baccarat E',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e93829cf98ca757a18b621f0f22525e6ae70eec5/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24210,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Salon-Priv-Baccarat-F',
        Name: 'Salon Privé Baccarat F',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/9ce2914e933e42046af2195ff7c592e551565070/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24211,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-Blackjack-K',
        Name: 'Speed Blackjack K',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/bd09a78491e010bc2f6259b22874959642945939/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24212,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Salon-Priv-Baccarat-G',
        Name: 'Salon Privé Baccarat G',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/f40e4fd033d16da2e4f333b08116914d8c44a880/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24213,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Salon-Priv-Blackjack-G',
        Name: 'Salon Privé Blackjack G',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ed247f82534937e60413d1a5dd988d02e8035e9c/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24214,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-VIP-Blackjack-A',
        Name: 'Speed VIP Blackjack A',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/9ebda3174a3e30f4f131f662bea449a4f37aee98/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24215,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-VIP-Blackjack-B',
        Name: 'Speed VIP Blackjack B',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/10534e52a7ed01bef343ac69518cb98fffa369c8/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24216,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-VIP-Blackjack-C',
        Name: 'Speed VIP Blackjack C',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d5dc69c9932a326e11dbbc48a99b2809aa056f73/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24217,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-VIP-Blackjack-E',
        Name: 'Speed VIP Blackjack E',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/6e7b883e369840375c255177c2bfe0d9be279f99/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24218,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-VIP-Blackjack-F',
        Name: 'Speed VIP Blackjack F',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/0c0fbf815001c519efaeaf92ee700ea66d4ec88b/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24219,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-VIP-Blackjack-G',
        Name: 'Speed VIP Blackjack G',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/30ac1107dfdbf2c7cc920e21b9cd531664bbd613/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24220,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-Baccarat-R',
        Name: 'Speed Baccarat R',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/0119a320f93748f323278767b4f79a47b544d69a/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24221,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-Blackjack-L',
        Name: 'Speed Blackjack L',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/8fa6063b3536a5851544c8e777971b66a32180e9/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24222,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-Baccarat-S',
        Name: 'Speed Baccarat S',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/6fde0daca0186baca1acf672dc0fe5596b374f7d/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24223,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-Blackjack-M',
        Name: 'Speed Blackjack M',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/fe84445b2b7f601c1d3bd3f9f35610aca70d7b3d/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24224,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Bucharest-Blackjack-A',
        Name: 'Bucharest Blackjack A',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/9bab8adbe18c079aad52ac5102f90c226f9eb900/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24225,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Bucharest-Blackjack-B',
        Name: 'Bucharest Blackjack B',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/06711b3905ff3d68e027e54ffc60134ea43bfe79/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24226,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'First-Person-Top-Card',
        Name: 'First Person Top Card',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e31753e38d0a1524f79b6127c9b4052ead8d7416/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24227,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-VIP-Blackjack-H',
        Name: 'Speed VIP Blackjack H',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/2bb7d3dffbe8db6230c9223a1a8d735f2543cda8/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24228,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-VIP-Blackjack-I',
        Name: 'Speed VIP Blackjack I',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/eca76728dd20b487466fc4d5fe62544ab5f2fdc2/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24229,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-VIP-Blackjack-J',
        Name: 'Speed VIP Blackjack J',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/9f32097f6fd38ebb8e9a2fce84c41f24109c7194/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24230,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'First-Person-Golden-Wealth-Baccarat',
        Name: 'First Person Golden Wealth Baccarat',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/8704598972e00ffec314597c8c771bfdcd3e049f/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24231,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Cash-Or-Crash',
        Name: 'Cash Or Crash',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e86f6c27a2dfe3dd8caf4a1396eefc2f89313379/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24232,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-Baccarat-M',
        Name: 'Speed Baccarat M',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/51e37ef7c84d1117d5fe71549d2a8331639b4f00/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24233,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-Baccarat-P',
        Name: 'Speed Baccarat P',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/0c92fcbdd38324dcbdb834bd9cb0f358279fe95e/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24234,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-Baccarat-K',
        Name: 'Speed Baccarat K',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/63042b7972d204c313d166799b95f767b24c5d5a/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24235,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-Baccarat-Q',
        Name: 'Speed Baccarat Q',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ba92f5268c68164b43bafad87f829f4eb92e9bfb/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24236,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-Baccarat-O',
        Name: 'Speed Baccarat O',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/2cba2b25caa450d62bf1f09777e9dcb80c662973/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24237,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-Baccarat-L',
        Name: 'Speed Baccarat L',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/4afa867e07ca152a960daf0c2ab7f75744109bf8/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24238,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-Baccarat-N',
        Name: 'Speed Baccarat N',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/9b1c60672b4719b0c6902a93007c8bb519b3ec39/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24239,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Salon-Priv-Baccarat-B',
        Name: 'Salon Privé Baccarat B',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/8ee6e9237243889d2de78ad63b60bd49992ee111/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24240,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Salon-Priv-Baccarat-C',
        Name: 'Salon Privé Baccarat C',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/4f88c326f7f89773ee2c6cf29b81fdfc00853630/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24241,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Lobby-Poker',
        Name: 'Lobby Poker',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/b001c340bd9f9a1683df7432e51b3764dce16880/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24242,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Lobby-Top-Games',
        Name: 'Lobby Top Games',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/5bc050827e51b8bc3957b98d313629a152f738ac/Games Catalog image/image.jpeg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24243,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Lobby-Blackjack',
        Name: 'Lobby Blackjack',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/b7e28bc4ab2e0ca64e0aa23391b4d24cb80f694a/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24244,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Lobby-Baccarat-Sic-bo',
        Name: 'Lobby Baccarat Sic bo',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d8ba89fb8ca59c75f4197c0062b0ed6eee19ae88/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24245,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Lobby-Roulette',
        Name: 'Lobby Roulette',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/470feed8ca09ef95afde58a8c5766ba36498373d/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24246,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Craps',
        Name: 'Craps',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/da11c4cb4a68273741f7aeab7c2a75cadbffcf1d/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24247,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-Gamma',
        Name: 'Blackjack VIP Gamma',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ab147889303a2a51cac6116d38d7e60ca9a0d886/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24248,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-Beta',
        Name: 'Blackjack VIP Beta',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/96d28cc4a73fce81adb308ff6da93482d69c4f88/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24249,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blackjack-VIP-Alpha',
        Name: 'Blackjack VIP Alpha',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d53bcffcdd918fecff15172b08ffbf807bdabf2b/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24250,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Power-Blackjack',
        Name: 'Power Blackjack',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/30f649f613babf07388a90be3ec1207e2ca3d1b7/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24251,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Lightning-Baccarat',
        Name: 'Lightning Baccarat',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/dbb717fdfb6a20a895c254119674ed380d3116d0/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24252,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Three-Card-Poker',
        Name: 'Three Card Poker',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/1a935110d7e25abad6cb1a95b09ef92593891f74/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24253,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Monopoly-Live',
        Name: 'Monopoly Live',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/21eb4f6d6da2f85b00b1c460ad198ddaf6a4ad22/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24254,
        ProviderId: 10,
        VendorId: 17,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Ultimate-Texas-Holdem',
        Name: 'Ultimate Texas Holdem',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/56525ef7c31757758c12475873e3a9d50e4bb794/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24256,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: '5-Magician-s-Secrets',
        Name: "5 Magician's Secrets",
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/560aa8051044a10aff395c08f28ca36f230f5bc2/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24257,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blood-Moon-Wheel',
        Name: 'Blood Moon Wheel',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/3b1f540b886cb09cd658fff471c88e7fd4fd7438/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24258,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Cave-of-Gold-Riches',
        Name: 'Cave of Gold Riches',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ebd397728bcfb76c6207e9fb6293a8d10e95ea03/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24259,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'XBet-MergeUp',
        Name: 'XBet MergeUp',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/1606bcdaad52ad3c142e65faf0cef17353996a78/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24260,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Love2Play-Merge-Up',
        Name: 'Love2Play Merge Up',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/b69b0911b13b457ffac934bcaa2cde4de6ae7f98/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24261,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Sky-God-Zeus-3x3-',
        Name: 'Sky God Zeus (3x3)',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ae20d806119405b155474d4f2d2b396b9638315f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24262,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'The-Irish-Game',
        Name: 'The Irish Game',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/bf4009b7125afca997b9994bbcf9a095add119c1/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24263,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Wheel-of-Bliss-3x3-',
        Name: 'Wheel of Bliss (3x3)',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d4dee55dcfa3ab9b7497562ccd0ede5ab7f7b000/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24264,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Lucky-Dragon-MultiDice-X',
        Name: 'Lucky Dragon MultiDice X',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/95e2b91da079277b961784bf6d7ac476d1669db3/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24265,
        ProviderId: 29,
        VendorId: 119,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Sexy-Blink',
        Name: 'Sexy Blink',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/f1b66c6d441706e467a1e36ace7f30aced24456d/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24266,
        ProviderId: 29,
        VendorId: 119,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Football-Mines',
        Name: 'Football Mines',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/5029a9df266e706661533f29b195b67498b74588/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24267,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Deep-Fishing',
        Name: 'Deep Fishing',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/617641a9c0ab581333461782e2280b8e762220e2/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24268,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Dark-Magic-War',
        Name: 'Dark Magic War',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/b9a1e5257ffd7edc4499e02bbb1850d78c410ad1/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24269,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: '9x-Monkey-Lock-2-Spin',
        Name: '9x Monkey Lock 2 Spin',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/f347f40289f13234659cec9936e4210a3ccc76ec/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24270,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Assassin-Mission',
        Name: 'Assassin Mission',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/1f35e36bdfa9563318dbdae5ee712e1ea7571765/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24271,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Kick-Cash-Panda',
        Name: 'Kick Cash Panda',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/840ac69b4be7994d1d85bffdc67076c094e062cb/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24272,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Mystery-Detective-Fusion-Reels',
        Name: 'Mystery Detective Fusion Reels',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d6fdc594c8940ebb388b66b5b33c66b6914759d0/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24273,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Woodland-of-Wishes',
        Name: 'Woodland of Wishes',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/176b5f4c9ee3bc57f8d79cf116e28fb3d791939d/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24274,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Woodland-Quest',
        Name: 'Woodland Quest',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/97745e73789664a7e797dfa17d67d0d28fe15f3b/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24275,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Sky-God-Zeus-Pull-Tabs-',
        Name: 'Sky God Zeus (Pull Tabs)',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/22d2224bf1f998befa631672571ea24cf64a0a41/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24276,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Wow-Panda',
        Name: 'Wow Panda',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/1dc0651295cee2c3e14d4bacf06e1e8ed3b48ee2/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24277,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Wild-Fruits-81',
        Name: 'Wild Fruits 81',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e108e3eeb18100e114ef03c7db5f177e987437f9/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24278,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Wheel-of-Bliss-Pull-Tabs-',
        Name: 'Wheel of Bliss (Pull Tabs)',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/925fa9bcf4211790e7e2b5415247a3667f46ce8f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24279,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Witch-s-Enchanted-Wheel',
        Name: "Witch's Enchanted Wheel",
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/9f58fd526c5ae210843d866d7a8d7c2d518ac2e7/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24280,
        ProviderId: 30,
        VendorId: 120,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Heist-Bank-Rush-Hold-Win-',
        Name: 'Heist: Bank Rush - Hold & Win™',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/40a9ad69dbdfa2217689c2ec9e42dd2e14774ab4/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24281,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Elvis-Buffalo-Trueways',
        Name: 'Elvis Buffalo Trueways',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/0e2dcfd12c0091d852facd216490063beaf79a68/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24282,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Royal-Rio-Birds-3x3-',
        Name: 'Royal Rio Birds (3x3)',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/abbdce70ab8ff26973fc0b1986d9a5cc078c2078/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24283,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Relic-of-Tomb',
        Name: 'Relic of Tomb',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/780e71572baa0438976d06baed018fa34e7b6f65/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24284,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Red-Hot-Sevens-Pull-Tabs-',
        Name: 'Red Hot Sevens (Pull Tabs)',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/79229ba1b72837f784dc6f6a7afad93035ebbaa5/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24285,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Golden-Crab',
        Name: 'Golden Crab',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/5f3646049cfeedda3fdbe607e0d033b186330cbe/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24286,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Million-Lucky-Wheel',
        Name: 'Million Lucky Wheel',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/a072e488c741e58f17b478cf3dbf38b4833d1130/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24287,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'King-of-the-God-Zeus-Lock-2-Spin',
        Name: 'King of the God Zeus Lock 2 Spin',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/7f3730a45039968c1d8b6ed4377df8cc7736a5d4/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24288,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Songkran-Warfare',
        Name: 'Songkran Warfare',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/4063d2aa3bcf41668d687a2cbd8c407e448c56a5/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24289,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Crazy-Blackbeard',
        Name: 'Crazy Blackbeard',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/2e64e1643a2940fb0c3076a3675acc4bf538bce1/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24290,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-King',
        Name: 'Speed King',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/990c2fea79d640cc6e0ab93ce7afb3d043d9ced4/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24291,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Secrets-of-Ancient-Egypt-Reel-Respin-',
        Name: 'Secrets of Ancient Egypt (Reel Respin)',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/211483d9122e43f32a332ff725336e3459ac1bf2/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24292,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Royal-Rio-Birds-Pull-Tabs-',
        Name: 'Royal Rio Birds (Pull Tabs)',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ae80bc1b12f2ac9215019249a07f363559021b4c/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24293,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: '3-Kings-Scratch',
        Name: '3 Kings Scratch',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/77bb59e882d4c4b609916dddc4feb7dbad55589b/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24294,
        ProviderId: 30,
        VendorId: 120,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Coins-of-Ra-Hold-Win-',
        Name: 'Coins of Ra - Hold & Win™',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/bf81432f50c7040542b949fa475167fcbe4bfa47/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24295,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Diamond-of-Jungle',
        Name: 'Diamond of Jungle',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/8f2144b5b100ca3ba211c6198c218c489779522f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24437,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Tap-Heroes',
        Name: 'Tap Heroes',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/8f2144b5b100ca3ba211c6198c218c489779522f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24296,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Enchanted-Clovers',
        Name: 'Enchanted Clovers',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ada8f091f6b7ce95ecaeb7d0be9cd024ff7e58dc/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24438,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'The-Frog-Prince',
        Name: 'The Frog Prince',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ada8f091f6b7ce95ecaeb7d0be9cd024ff7e58dc/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24297,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Exploding-Gold-Mines',
        Name: 'Exploding Gold Mines',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/5caacb73ffc39dc886abad1b63fd3b7b1ccf6ac3/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24439,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'The-Master-Cat',
        Name: 'The Master Cat',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/5caacb73ffc39dc886abad1b63fd3b7b1ccf6ac3/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24298,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Forest-of-Mysteries',
        Name: 'Forest of Mysteries',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/967a9bf0120a290f11a436c280f521438372d115/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24440,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Zodiac-Hunting',
        Name: 'Zodiac Hunting',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/967a9bf0120a290f11a436c280f521438372d115/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24299,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Beauty-Pageant',
        Name: 'Beauty Pageant',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/28439e50f554616aceb296aaf4cc0b1b846bf95c/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24441,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Thrill-To-Grill',
        Name: 'Thrill To Grill',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/28439e50f554616aceb296aaf4cc0b1b846bf95c/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24300,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Bonus-Mania-Plinko',
        Name: 'Bonus Mania Plinko',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/34443aad4b7837fc29c30b8d0874241152daad83/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24442,
        ProviderId: 30,
        VendorId: 120,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Super-Golden-Dragon-InfernoTM',
        Name: 'Super Golden Dragon InfernoTM',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/34443aad4b7837fc29c30b8d0874241152daad83/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24301,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Deep-Overlord',
        Name: 'Deep Overlord',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/a8dba3c3000d09936c04bb57dbc7ba288d7a35fd/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24443,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Mice-Magic-Wonder-Spin',
        Name: 'Mice & Magic Wonder Spin',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/a8dba3c3000d09936c04bb57dbc7ba288d7a35fd/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24302,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Fantasy-Universe',
        Name: 'Fantasy Universe',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e084bfe4aac722ef122888d611eaea76c6fe5ecc/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24444,
        ProviderId: 30,
        VendorId: 120,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Fruit-Mojo-',
        Name: 'Fruit Mojo™',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e084bfe4aac722ef122888d611eaea76c6fe5ecc/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24303,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Mayan-Civilization',
        Name: 'Mayan Civilization',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/30ebb7d15a82694d345cc67eb73f10c49cbacbc1/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24445,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Big-Bang-Boom',
        Name: 'Big Bang Boom',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/30ebb7d15a82694d345cc67eb73f10c49cbacbc1/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24304,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Werewolf',
        Name: 'Werewolf',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/f3ca57d0966692bb2c25244b045fadedd6fb4abf/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24446,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Dragon-Pearl',
        Name: 'Dragon Pearl',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/f3ca57d0966692bb2c25244b045fadedd6fb4abf/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24305,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Crazy-Carnival-Wheel',
        Name: 'Crazy Carnival Wheel',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/9ace6fe31068c1384287664536ae319af2b21fb3/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24447,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Golf-Master',
        Name: 'Golf Master',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/9ace6fe31068c1384287664536ae319af2b21fb3/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24306,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Dwarf-s-Cave-Treasures',
        Name: "Dwarf's Cave Treasures",
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d7693d6d30a9f938dd022f9d8e951abb6d026612/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24448,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Gypsy-Fusion-Reels',
        Name: 'Gypsy Fusion Reels',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d7693d6d30a9f938dd022f9d8e951abb6d026612/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24307,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Clover-Frenzy',
        Name: 'Clover Frenzy',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/723cf5ea6b649eeff990e597bf2a785960b1571b/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24449,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Monkey-King',
        Name: 'Monkey King',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/723cf5ea6b649eeff990e597bf2a785960b1571b/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24308,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Cowboy-Golden-Age',
        Name: 'Cowboy Golden Age',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/c509a3c50c1ebe55a2049d902442d40f6ccfd0c8/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24450,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Shenron-Hunter',
        Name: 'Shenron Hunter',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/c509a3c50c1ebe55a2049d902442d40f6ccfd0c8/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24309,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Clover-Wins-Reel-Respin-',
        Name: 'Clover Wins (Reel Respin)',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e3d74aaf7a88fca6972da0cbbe36539875ec4a3a/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24451,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Thief',
        Name: 'Thief',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e3d74aaf7a88fca6972da0cbbe36539875ec4a3a/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24310,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Cleopatra-s-Rituals-Reel-Respin-',
        Name: "Cleopatra's Rituals (Reel Respin)",
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d392a3364aa58d808981293d10554fc959a6c033/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24452,
        ProviderId: 30,
        VendorId: 120,
        VendorName: null,
        IsFavorite: false,
        NameInURL: '72-FortunesTM',
        Name: '72 FortunesTM',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d392a3364aa58d808981293d10554fc959a6c033/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24311,
        ProviderId: 30,
        VendorId: 120,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Tiger-s-Luck-Hold-Win-',
        Name: "Tiger's Luck - Hold & Win™",
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ced7803b7d003b1a3eef0e58fa18256b0f1c2e78/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24453,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Bone-Bonanza',
        Name: 'Bone Bonanza',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ced7803b7d003b1a3eef0e58fa18256b0f1c2e78/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24312,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Dragon-s-Crash',
        Name: "Dragon's Crash",
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/6570ed3fdddadf8059db08285f1e3c2f1f8f0da7/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24454,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Maneki-88-Fortunes',
        Name: 'Maneki 88 Fortunes',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/6570ed3fdddadf8059db08285f1e3c2f1f8f0da7/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24313,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'UFO-Hunt',
        Name: 'UFO Hunt',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/eb311a23ee070ef8de84942d94dcca0301541598/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24455,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Lost-Relics-2',
        Name: 'Lost Relics 2',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/eb311a23ee070ef8de84942d94dcca0301541598/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24314,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Rock-Parrot',
        Name: 'Rock Parrot',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/61a1a17818384ead9b3c58ca84b984b5f82e7fc7/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24456,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Shark-Fight',
        Name: 'Shark Fight',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/61a1a17818384ead9b3c58ca84b984b5f82e7fc7/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24315,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Book-of-Skull',
        Name: 'Book of Skull',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/fec823f9aa6f242c460ea09ac355f8f001ad59d7/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24457,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: '101-Candies',
        Name: '101 Candies',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/fec823f9aa6f242c460ea09ac355f8f001ad59d7/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24316,
        ProviderId: 31,
        VendorId: 121,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Wilds-Gods',
        Name: 'Wilds & Gods',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/3b0ad1731c60e8aaf37d4496cdabcd80661769a5/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24458,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Wild-Cash-Dice',
        Name: 'Wild Cash Dice',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/3b0ad1731c60e8aaf37d4496cdabcd80661769a5/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24317,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Inca-Lost-Treasure',
        Name: 'Inca Lost Treasure',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/99bae2b7e5a4b2c809da5bf342302f3922f1285c/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24459,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Jimi-Hendrix-Online-Slot',
        Name: 'Jimi Hendrix Online Slot',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/99bae2b7e5a4b2c809da5bf342302f3922f1285c/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24318,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Olympus-Gods',
        Name: 'Olympus Gods',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/c36be6fcba1f63e04357859d57c811f3e8a7ebeb/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24460,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Riches-of-Midgard-Land-and-Expand',
        Name: 'Riches of Midgard: Land and Expand',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/c36be6fcba1f63e04357859d57c811f3e8a7ebeb/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24319,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'The-Wild-Four',
        Name: 'The Wild Four',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/76dd13693d6efd231e6612836a6524d11517940a/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24461,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'The-Wish-Master-Megaways-',
        Name: 'The Wish Master™ Megaways™',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/76dd13693d6efd231e6612836a6524d11517940a/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24320,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'The-Crypto',
        Name: 'The Crypto',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d4aec86336ecc59c91cb5437b7927f1d8aca979f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24462,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Twin-Spin-XXXtreme',
        Name: 'Twin Spin™ XXXtreme',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d4aec86336ecc59c91cb5437b7927f1d8aca979f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24321,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Romani-Secret',
        Name: 'Romani Secret',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/007a326fe5af814800b7705806f15b610f867a28/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24463,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Wild-Turkey-Megaways-',
        Name: 'Wild Turkey™ Megaways™',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/007a326fe5af814800b7705806f15b610f867a28/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24322,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Party-Girl-Deluxe-Lock-2-Spin',
        Name: 'Party Girl Deluxe Lock 2 Spin',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/737d9235c6d22f8075459a9b76f479e526d11e54/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24464,
        ProviderId: 30,
        VendorId: 120,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'April-Fury-And-The-Chamber-Of-ScarabsTM',
        Name: 'April Fury And The Chamber Of ScarabsTM',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/737d9235c6d22f8075459a9b76f479e526d11e54/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24323,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'JELLY-BELLY-MEGAWAYS-',
        Name: 'JELLY BELLY MEGAWAYS ',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/46acf25cdcb467b05c957a0df31b7d5ab04cf445/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24465,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Beach-Invaders',
        Name: 'Beach Invaders',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/46acf25cdcb467b05c957a0df31b7d5ab04cf445/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24324,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Lucky-8-Merge-Up',
        Name: 'Lucky 8 Merge Up',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e2d2ef2024df14ecd8afea9d24fbc7f09b87a852/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24466,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Space-Wars-2-Powerpoints',
        Name: 'Space Wars 2: Powerpoints',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e2d2ef2024df14ecd8afea9d24fbc7f09b87a852/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24325,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'RAGE',
        Name: 'RAGE',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/9c175791558ccdd8b87c0dbf5c2e8ff0407ba929/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24467,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Dazzle-Me',
        Name: 'Dazzle Me',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/9c175791558ccdd8b87c0dbf5c2e8ff0407ba929/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24326,
        ProviderId: 30,
        VendorId: 120,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Take-the-Vault-Hold-Win',
        Name: 'Take the Vault - Hold & Win',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/2425d4fad33b624748e9b394ee7ce1f752da1b25/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24468,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Street-Fighter-II-The-World-Warrior-Slot',
        Name: 'Street Fighter II: The World Warrior Slot',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/2425d4fad33b624748e9b394ee7ce1f752da1b25/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24327,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Pop-Zen',
        Name: 'Pop Zen',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ebd33c1f92bc96549c13c0022397a838266acd1c/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24469,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Butterfly-Staxx',
        Name: 'Butterfly Staxx',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ebd33c1f92bc96549c13c0022397a838266acd1c/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24328,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Monster-Go-Shopping',
        Name: 'Monster Go Shopping',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/19e7e7f09ed84f6399d8f202e8df512ca11add05/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24470,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Cornelius',
        Name: 'Cornelius',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/19e7e7f09ed84f6399d8f202e8df512ca11add05/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24329,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Money-God',
        Name: 'Money God',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/b9a6a8dc9069cfc381ed913620900d969f26d6be/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24471,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Fruit-Shop-Megaways',
        Name: 'Fruit Shop Megaways',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/b9a6a8dc9069cfc381ed913620900d969f26d6be/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24330,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Carnival-Queen',
        Name: 'Carnival Queen',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/52fe1190df914260d53cdf7e3cf1e9cc5f9dcc22/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24472,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Halloween-Jack',
        Name: 'Halloween Jack',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/52fe1190df914260d53cdf7e3cf1e9cc5f9dcc22/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24331,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Shrove-Tuesday',
        Name: 'Shrove Tuesday',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/62b2d705c401d2f6f71a1e5e4d82c5b3a96ef28e/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24473,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Jungle-Spirit-Call-of-the-Wild',
        Name: 'Jungle Spirit: Call of the Wild',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/62b2d705c401d2f6f71a1e5e4d82c5b3a96ef28e/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24332,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Golden-and-Silver-Horn',
        Name: 'Golden and Silver Horn',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/422d1b3fa87024264634bbbd641b7f3ac8ae3242/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24474,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Pork-Knox',
        Name: 'Pork Knox',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/422d1b3fa87024264634bbbd641b7f3ac8ae3242/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24333,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Thunder-God',
        Name: 'Thunder God',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ac5a33c5c4df1f43dba83ecf06e31f4c3dd4f7ea/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24475,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Magic-Maid-Cafe',
        Name: 'Magic Maid Cafe',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ac5a33c5c4df1f43dba83ecf06e31f4c3dd4f7ea/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24334,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Aztec-Clusters-',
        Name: 'Aztec Clusters ',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/59abbbf56a5480ed51313610be665154c4ea493e/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24476,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Rise-of-Maya',
        Name: 'Rise of Maya',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/59abbbf56a5480ed51313610be665154c4ea493e/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24335,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Buckshot-Wilds',
        Name: 'Buckshot Wilds',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/a47622c14cdfd0dfad8369098290911dd8b37fb7/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24477,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Pyramid-Quest-for-Immortality',
        Name: 'Pyramid: Quest for Immortality',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/a47622c14cdfd0dfad8369098290911dd8b37fb7/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24336,
        ProviderId: 30,
        VendorId: 120,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Rockstar-World-Tour-Hold-Win-',
        Name: 'Rockstar World Tour - Hold & Win™',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/0f00c7bdc6eec764e3922f6fd5ce056d9f590dff/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24478,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Dazzle-Me-Megaways',
        Name: 'Dazzle Me Megaways',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/0f00c7bdc6eec764e3922f6fd5ce056d9f590dff/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24337,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Wild-Heart',
        Name: 'Wild Heart',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/81951550f333661129066600802c8229ec1e1b2f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24479,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Divine-Fortune-Megaways',
        Name: 'Divine Fortune Megaways',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/81951550f333661129066600802c8229ec1e1b2f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24338,
        ProviderId: 31,
        VendorId: 121,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Foxy-Hot-20',
        Name: 'Foxy Hot 20',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/af98a07aeeb997230fd761bf3300cecbe93a37d9/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24480,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'King-of-3-Kingdoms',
        Name: 'King of 3 Kingdoms',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/af98a07aeeb997230fd761bf3300cecbe93a37d9/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24339,
        ProviderId: 31,
        VendorId: 121,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Smash-X',
        Name: 'Smash X',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/3a01e103fdd96e2bdf7a1efcde5ba521e5f691fb/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24481,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Twin-Happiness',
        Name: 'Twin Happiness',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/3a01e103fdd96e2bdf7a1efcde5ba521e5f691fb/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24340,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: '-Pirates-Party',
        Name: ' Pirates Party',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/2ddd5bb9e2fb569bf3e48d955b01f89567d2756e/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24482,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Twin-Spin',
        Name: 'Twin Spin',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/2ddd5bb9e2fb569bf3e48d955b01f89567d2756e/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24341,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Octopus-Legend',
        Name: 'Octopus Legend',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/0fb1c1b4466539408a48f72bddfd93d0bca6f55a/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24483,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Hotline-2',
        Name: 'Hotline 2',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/0fb1c1b4466539408a48f72bddfd93d0bca6f55a/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24342,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Dragon-Ingot',
        Name: 'Dragon Ingot',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/0b6e52d4b37e53f5d9e7c73812237b7f18efe035/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24484,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Rome-The-Golden-Age',
        Name: 'Rome: The Golden Age',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/0b6e52d4b37e53f5d9e7c73812237b7f18efe035/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24343,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Lion-on-Ridge',
        Name: 'Lion on Ridge',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ab3cd0271d333f3626ffe9cb3f3f5353d3bf941f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24485,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Serengeti-Kings',
        Name: 'Serengeti Kings',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ab3cd0271d333f3626ffe9cb3f3f5353d3bf941f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24344,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Lucky-Golden-Dragon-Lock-2-Spin',
        Name: 'Lucky Golden Dragon Lock 2 Spin',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/07ddde71c1aea54d0983b39f8a41c2de5ec03af4/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24486,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Starburst',
        Name: 'Starburst',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/07ddde71c1aea54d0983b39f8a41c2de5ec03af4/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24345,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Carnival-of-Venice',
        Name: 'Carnival of Venice',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/24cafad5cc7a1623b19d81fce4ccb8de69603f48/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24487,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Superstars',
        Name: 'Superstars',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/24cafad5cc7a1623b19d81fce4ccb8de69603f48/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24346,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Neonmal',
        Name: 'Neonmal',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/998b6702955932a37fd01424bdb1b47d72505ea5/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24488,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Fruit-Shop',
        Name: 'Fruit Shop',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/998b6702955932a37fd01424bdb1b47d72505ea5/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24347,
        ProviderId: 30,
        VendorId: 120,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Plinko-Rush',
        Name: 'Plinko Rush',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/fe4829509004c368e0503d6b03d117b14a509158/Games Catalog image/image.jpeg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24489,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Steam-Tower',
        Name: 'Steam Tower',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/fe4829509004c368e0503d6b03d117b14a509158/Games Catalog image/image.jpeg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24348,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: '-God-of-Wealth-Hold-And-Win',
        Name: ' God of Wealth Hold And Win',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d81850d2197ecdc45de90710ad5a98b23024f39e/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24490,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Reel-Rush',
        Name: 'Reel Rush',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d81850d2197ecdc45de90710ad5a98b23024f39e/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24349,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'BGaming-Jackpot',
        Name: 'BGaming Jackpot',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/aa932b14dcc8ff0f602965a92b7b4be787d2791a/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24491,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Jack-Hammer',
        Name: 'Jack Hammer',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/aa932b14dcc8ff0f602965a92b7b4be787d2791a/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24350,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Bubble-Shooter',
        Name: 'Bubble Shooter',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/33da2395514207ff075e5c1fa80886b78e8d26d8/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24492,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Jack-Hammer-2-Fishy-Business',
        Name: 'Jack Hammer 2: Fishy Business',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/33da2395514207ff075e5c1fa80886b78e8d26d8/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24351,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Philosopher-s-Roulette',
        Name: "Philosopher's Roulette",
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/766ff4640c71954c4e92ec41de3e011c5de9b5f4/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24493,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Dead-Or-Alive',
        Name: 'Dead Or Alive',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/766ff4640c71954c4e92ec41de3e011c5de9b5f4/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24352,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Rocket-Race',
        Name: 'Rocket Race',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/5290156321830d24fc36bd4bfdf709e6fee46e8d/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24494,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Super-Striker',
        Name: 'Super Striker',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/5290156321830d24fc36bd4bfdf709e6fee46e8d/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24353,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Crazy-Guava-Lock-2-Spin',
        Name: 'Crazy Guava Lock 2 Spin',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/8dc1432e75ddc83bd6b468890ab553379072324c/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24495,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Blood-Suckers',
        Name: 'Blood Suckers',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/8dc1432e75ddc83bd6b468890ab553379072324c/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24354,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Chess-King',
        Name: 'Chess King',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/85c88f17350cc838a795c1fb8cd3565edaedbd0f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24496,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Fairytale-Legends-Mirror-Mirror',
        Name: 'Fairytale Legends: Mirror Mirror',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/85c88f17350cc838a795c1fb8cd3565edaedbd0f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24355,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Chinese-Phoenix',
        Name: 'Chinese Phoenix',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/91165ece560ebfc9cad18b7088acb999c480521a/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24497,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Savage-Buffalo-Spirit-Megaways',
        Name: 'Savage Buffalo Spirit Megaways',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/91165ece560ebfc9cad18b7088acb999c480521a/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24356,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Wild-Tiger',
        Name: 'Wild Tiger',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/1f71f3dfbb77728ef5bde17d96555888c5864afd/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24498,
        ProviderId: 30,
        VendorId: 120,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Thunder-Clash-',
        Name: 'Thunder Clash™',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/1f71f3dfbb77728ef5bde17d96555888c5864afd/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24357,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Capymania-Orange',
        Name: 'Capymania Orange',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/bc10d239c6f41fd073d1acf603f20e7a4c4ba94b/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24499,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Onmyoji',
        Name: 'Onmyoji',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/bc10d239c6f41fd073d1acf603f20e7a4c4ba94b/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24358,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Capymania-Green',
        Name: 'Capymania Green',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/1dfb9e0890a70ba268ef6852b2552fd613da9d6e/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24500,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Dice-Million',
        Name: 'Dice Million',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/1dfb9e0890a70ba268ef6852b2552fd613da9d6e/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24359,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Capymania-Yellow',
        Name: 'Capymania Yellow',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/290b25c38963ea077a4f8709c010cbf5f7a733f0/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24501,
        ProviderId: 31,
        VendorId: 121,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Multi-Hot-Ways',
        Name: 'Multi Hot Ways',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/290b25c38963ea077a4f8709c010cbf5f7a733f0/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24360,
        ProviderId: 31,
        VendorId: 121,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Crash-Duel-X',
        Name: 'Crash Duel X',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d354e8efe06ec35e404758d02c317baba7092407/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24502,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Double-Happiness',
        Name: 'Double Happiness',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d354e8efe06ec35e404758d02c317baba7092407/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24361,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Dragon-Boom',
        Name: 'Dragon Boom',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/24b703a65e4abab5aad9e833617d2ce51af22ffe/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24503,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Kick-Pumpkin',
        Name: 'Kick Pumpkin',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/24b703a65e4abab5aad9e833617d2ce51af22ffe/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24362,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Hottest-666',
        Name: 'Hottest 666',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/772bc715a2ed799bec734be8f2adcf2fc9e043c0/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24504,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'La-Tomatina',
        Name: 'La Tomatina',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/772bc715a2ed799bec734be8f2adcf2fc9e043c0/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24363,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Gemza',
        Name: 'Gemza',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/71fc564f32f654230fb753d1c1c9a59a8b9be452/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24505,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Lilith',
        Name: 'Lilith',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/71fc564f32f654230fb753d1c1c9a59a8b9be452/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24364,
        ProviderId: 30,
        VendorId: 120,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Tycoons-Billionaire-Bucks-Hold-Win-',
        Name: 'Tycoons: Billionaire Bucks - Hold & Win™',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ee761d7f34128e4e7c8482eac088e4127ca09abf/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24506,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Love-Game',
        Name: 'Love Game',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ee761d7f34128e4e7c8482eac088e4127ca09abf/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24365,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Ice-Scratch-Bronze',
        Name: 'Ice Scratch Bronze',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/6fc1cc3e1075dff6fb3bf861d580f059352b1b94/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24507,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Nie-Xiaoqian',
        Name: 'Nie Xiaoqian',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/6fc1cc3e1075dff6fb3bf861d580f059352b1b94/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24366,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Iron-Hero',
        Name: 'Iron Hero',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e27c3ba01d019157e406edb08e273da57f48bdf8/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24508,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Luck-Magic',
        Name: 'Luck & Magic',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e27c3ba01d019157e406edb08e273da57f48bdf8/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24367,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Rudolph',
        Name: 'Rudolph',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/84af2cf83d04301a0bd2f59e35dbd16f0d084ade/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24509,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Dice-Bonanza',
        Name: 'Dice Bonanza',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/84af2cf83d04301a0bd2f59e35dbd16f0d084ade/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24368,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Romeo-and-Juliet',
        Name: 'Romeo and Juliet',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/be844a04504ec3a4b5b37f847e5f5a490e4572a4/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24510,
        ProviderId: 29,
        VendorId: 119,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speed-Blackjack',
        Name: 'Speed Blackjack',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/be844a04504ec3a4b5b37f847e5f5a490e4572a4/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24369,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Wind-Lion-God',
        Name: 'Wind Lion God',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/f51254ca575f889806679a12f36272c12381f1ba/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24511,
        ProviderId: 29,
        VendorId: 119,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Mines',
        Name: 'Mines',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/f51254ca575f889806679a12f36272c12381f1ba/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24370,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Speakeasy-2-Fusion-Reels',
        Name: 'Speakeasy 2 Fusion Reels',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ef5658a52f8b0c837ba9155abc93da34f5f88ff5/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24512,
        ProviderId: 29,
        VendorId: 119,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'PlinkoS',
        Name: 'PlinkoS',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ef5658a52f8b0c837ba9155abc93da34f5f88ff5/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24371,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: '7-Sinners',
        Name: '7 Sinners',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/b25e4485ea2fdd348cdc1b9376d09e8f928aef73/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24513,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Poseidon-Battle',
        Name: 'Poseidon Battle',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/b25e4485ea2fdd348cdc1b9376d09e8f928aef73/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24372,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Acceptance-test',
        Name: 'Acceptance test',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/916ee292df1d9967143f23bbb8ce07cab03383ee/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24514,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Beast-Band',
        Name: 'Beast Band',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/916ee292df1d9967143f23bbb8ce07cab03383ee/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24373,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: '-Gem-Crush',
        Name: ' Gem Crush',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d6481664b912cb48e12a0f40247f9905029dc508/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24515,
        ProviderId: 31,
        VendorId: 121,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'SlicerX',
        Name: 'SlicerX',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d6481664b912cb48e12a0f40247f9905029dc508/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24374,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Slot-Machine',
        Name: 'Slot Machine',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e65eac7b6fbb5e872e34e5658e5d9512241c3b75/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24516,
        ProviderId: 31,
        VendorId: 121,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'TowerX',
        Name: 'TowerX',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e65eac7b6fbb5e872e34e5658e5d9512241c3b75/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24375,
        ProviderId: 31,
        VendorId: 121,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Mine-Island',
        Name: 'Mine Island',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/6d977e5aa1a134b9dcdfa57600ba9615c922aabf/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24517,
        ProviderId: 31,
        VendorId: 121,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Joker-s-4-Bonuses',
        Name: "Joker's 4 Bonuses",
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/6d977e5aa1a134b9dcdfa57600ba9615c922aabf/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24376,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Gift-X',
        Name: 'Gift X',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/3695c5b2eab381b09010a560547185052cbbf15f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24518,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Pirates-and-Cannonballs',
        Name: 'Pirates and Cannonballs',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/3695c5b2eab381b09010a560547185052cbbf15f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24377,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Crab-Trap',
        Name: 'Crab Trap',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/a0536f271c33762587c8814c2559b8ec9dc18c61/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24519,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Enchanted-Clovers-3x3-',
        Name: 'Enchanted Clovers (3x3)',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/a0536f271c33762587c8814c2559b8ec9dc18c61/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24378,
        ProviderId: 30,
        VendorId: 120,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Enchanted-Forest-of-FortuneTM',
        Name: 'Enchanted: Forest of FortuneTM',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/c1264b8304ff2f42c6ddd8fe5c6a0cdd400b5ec2/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24520,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Enchanted-Clovers-Reel-Respin-',
        Name: 'Enchanted Clovers (Reel Respin)',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/c1264b8304ff2f42c6ddd8fe5c6a0cdd400b5ec2/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24379,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Jingle-Bells-Bonanza',
        Name: 'Jingle Bells Bonanza',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/fe7deea027ab8647dd35c5dcac09efc240aa687c/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24521,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Evil-Witch-Laugh',
        Name: 'Evil Witch Laugh',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/fe7deea027ab8647dd35c5dcac09efc240aa687c/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24380,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Dragon-City',
        Name: 'Dragon City',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/4876e2818c013e6d1371ee18881e201b70c1f514/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24522,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Hot-Circus-Show',
        Name: 'Hot Circus Show',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/4876e2818c013e6d1371ee18881e201b70c1f514/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24381,
        ProviderId: 30,
        VendorId: 120,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Astro-Cash-Hold-Win-',
        Name: 'Astro Cash - Hold & Win™',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/fd05870235871353e41339b5dfc4087d94aaf5cc/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24523,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Old-West-Street',
        Name: 'Old West Street',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/fd05870235871353e41339b5dfc4087d94aaf5cc/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24382,
        ProviderId: 31,
        VendorId: 121,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Hunter-X',
        Name: 'Hunter X',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/55c7092fbf8c98065a815d89847af124f24a89e3/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24524,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Lucky-Card-Layout',
        Name: 'Lucky Card Layout',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/55c7092fbf8c98065a815d89847af124f24a89e3/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24383,
        ProviderId: 31,
        VendorId: 121,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'HELICOPTER-X',
        Name: 'HELICOPTER X',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/63037ccaf27b600752009f2b3914393be34133e8/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24525,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Night-Crazy-Spell',
        Name: 'Night Crazy Spell',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/63037ccaf27b600752009f2b3914393be34133e8/Games Catalog image/image.png',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24384,
        ProviderId: 30,
        VendorId: 120,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Triple-Lucky-8-s-',
        Name: 'Triple Lucky 8’s™',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/c7e8dbad0d3a015c243d031acfa79b0d43dbc64b/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24526,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Penalty-Turbo',
        Name: 'Penalty Turbo',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/c7e8dbad0d3a015c243d031acfa79b0d43dbc64b/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24385,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Tramp-Day',
        Name: 'Tramp Day',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/133d5af1c3989d393a80a3fbe8e8c2af2c011809/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24527,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Pirate-Lucky-Belt',
        Name: 'Pirate Lucky Belt',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/133d5af1c3989d393a80a3fbe8e8c2af2c011809/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24386,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Crush-Pirate-Ship',
        Name: 'Crush Pirate Ship',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/95b25c55186f888602f6a068361b73f891f1976e/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24528,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Pirate-Wheel',
        Name: 'Pirate Wheel',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/95b25c55186f888602f6a068361b73f891f1976e/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24387,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Dragon-Hunter',
        Name: 'Dragon Hunter',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/8c50ea1d28a1539787e721c5e52329be4da924f2/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24529,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Chilli-Tumble',
        Name: 'Chilli Tumble',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/8c50ea1d28a1539787e721c5e52329be4da924f2/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24388,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Flaming-Monkey-Classic',
        Name: 'Flaming Monkey Classic',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/4185ef4d53301d542181df5828364590314985dd/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24530,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Clover-Wins-3x3-',
        Name: 'Clover Wins (3x3)',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/4185ef4d53301d542181df5828364590314985dd/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24389,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Radiant-City',
        Name: 'Radiant City',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/0805e7f895988f89e7255bbcfc6f8668f9bded78/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24531,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Clover-Wins-Pull-Tabs-',
        Name: 'Clover Wins (Pull Tabs)',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/0805e7f895988f89e7255bbcfc6f8668f9bded78/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24390,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Repair-Robot',
        Name: 'Repair Robot',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ac6fada246330591ce1eed3242a84c6f080779be/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24532,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Cowboy-Shootout',
        Name: 'Cowboy Shootout',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ac6fada246330591ce1eed3242a84c6f080779be/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24391,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Tibet-Plateau',
        Name: 'Tibet Plateau',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/aabc29510ac06c7f456a7953521f4a1296f7ed19/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24533,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Dual-Zeus-Wheels-3x3-',
        Name: 'Dual Zeus Wheels (3x3)',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/aabc29510ac06c7f456a7953521f4a1296f7ed19/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24392,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Elk-Hunter',
        Name: 'Elk Hunter',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/3b1bfd022d0de526a75abc79feb49a393dde0168/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24534,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Dual-Zeus-Wheels-Pull-Tabs-',
        Name: 'Dual Zeus Wheels (Pull Tabs)',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/3b1bfd022d0de526a75abc79feb49a393dde0168/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24393,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Book-of-Panda-Megaways-',
        Name: 'Book of Panda Megaways™',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/1869c33678d655648827bcb99d2d2e08084965d2/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24535,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Egg-Hunter-3x3-',
        Name: 'Egg Hunter (3x3)',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/1869c33678d655648827bcb99d2d2e08084965d2/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24394,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Finn-and-the-Candy-Spin',
        Name: 'Finn and the Candy Spin',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d277efc58fef85bd7d01e93b1df22530977a7f3b/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24536,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Egg-Hunter-Pull-Tabs-',
        Name: 'Egg Hunter (Pull Tabs)',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d277efc58fef85bd7d01e93b1df22530977a7f3b/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24395,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Mystic-Butterfly-Forest',
        Name: 'Mystic Butterfly Forest',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ccea91b30422c77a75ab2b24b112a9810ef652f9/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24537,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Calorie-Killer',
        Name: 'Calorie Killer',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ccea91b30422c77a75ab2b24b112a9810ef652f9/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24396,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Mystic-Hut',
        Name: 'Mystic Hut',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/914e594a8fdc2d4f867733e9671afd0fa50faa96/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24538,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Wild-Chicago',
        Name: 'Wild Chicago',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/914e594a8fdc2d4f867733e9671afd0fa50faa96/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24397,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Mystical-Case',
        Name: 'Mystical Case',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/eb87bec05bf39f66071c26a266a83ef9e22455ad/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24539,
        ProviderId: 30,
        VendorId: 120,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Wish-Granted-',
        Name: 'Wish Granted™',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/eb87bec05bf39f66071c26a266a83ef9e22455ad/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24398,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Old-Farmer-s-Ranch',
        Name: "Old Farmer's Ranch",
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/392801a6c34e1c90907097d2c13645fc95459c54/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24540,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Wizard-of-Wild',
        Name: 'Wizard of Wild',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/392801a6c34e1c90907097d2c13645fc95459c54/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24399,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Old-Haunted-House',
        Name: 'Old Haunted House',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/34fc5f8e8b4cbc3d8d9a85ed6f94e65ece4f0f7e/Games Catalog image/image.jpeg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24541,
        ProviderId: 30,
        VendorId: 120,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Pho-Sho',
        Name: 'Pho Sho',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/34fc5f8e8b4cbc3d8d9a85ed6f94e65ece4f0f7e/Games Catalog image/image.jpeg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24400,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Scary-Castle',
        Name: 'Scary Castle',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/4d592f8830f43687dc75ecd9048a9c2ac6ceea7f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24542,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Gemhalla',
        Name: 'Gemhalla',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/4d592f8830f43687dc75ecd9048a9c2ac6ceea7f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24401,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Terrible-Jack-O-Lantern',
        Name: 'Terrible Jack O Lantern',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/483cc406f10133d6bc5b28fcefaa5cc7bfc8d3f7/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24543,
        ProviderId: 32,
        VendorId: 122,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Buffalo-Goes-Wild',
        Name: 'Buffalo Goes Wild',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/483cc406f10133d6bc5b28fcefaa5cc7bfc8d3f7/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24402,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Texas-Wildness',
        Name: 'Texas Wildness',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/6da082796d552f74014f954365e9954530972cb3/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24544,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Monster-Collector',
        Name: 'Monster Collector',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/6da082796d552f74014f954365e9954530972cb3/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24403,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Wild-Parrot-Bonus-Pull-Tabs-',
        Name: 'Wild Parrot Bonus (Pull Tabs)',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e7554364f145baa799ec0651262d1f2f5c3574a4/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24545,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Limbo-XY',
        Name: 'Limbo XY',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e7554364f145baa799ec0651262d1f2f5c3574a4/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24404,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Wild-Parrot-Bonus-3x3-',
        Name: 'Wild Parrot Bonus (3x3)',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d73e185ce5b66577832f48627ed08efab9d20f69/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24546,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Happy-Animal-Farm',
        Name: 'Happy Animal Farm',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d73e185ce5b66577832f48627ed08efab9d20f69/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24405,
        ProviderId: 29,
        VendorId: 119,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Aztec-Plinko',
        Name: 'Aztec Plinko',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e6131fdbda16a67586940e602b822e0fdec9df4e/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24547,
        ProviderId: 31,
        VendorId: 121,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Crazy-Hunt-X',
        Name: 'Crazy Hunt X',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e6131fdbda16a67586940e602b822e0fdec9df4e/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24406,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: '100-Tricks',
        Name: '100 Tricks',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/7558606a8162d096357b9d11f65a99cd647d3960/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24548,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Savage-Buffalo-Spirit',
        Name: 'Savage Buffalo Spirit',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/7558606a8162d096357b9d11f65a99cd647d3960/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24407,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Black-Cowboy-Hat',
        Name: 'Black Cowboy Hat',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/456acb13918ece923648e0448cd7f235204b6fe9/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24549,
        ProviderId: 30,
        VendorId: 120,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Supreme-777-Jackpots',
        Name: 'Supreme 777 Jackpots',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/456acb13918ece923648e0448cd7f235204b6fe9/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24408,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Bright-Circus-Stars',
        Name: 'Bright Circus Stars',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/bc423ec28c6d6baf82e3bf314d840988bc2b58ef/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24550,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Lucky-Crew',
        Name: 'Lucky Crew',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/bc423ec28c6d6baf82e3bf314d840988bc2b58ef/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24409,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Forest-Idols',
        Name: 'Forest Idols',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e802429879b78335795848fbac16d07fbcd3d9af/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24551,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'KA-Fish-Party',
        Name: 'KA Fish Party',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e802429879b78335795848fbac16d07fbcd3d9af/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24410,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Fortune-Prediction',
        Name: 'Fortune Prediction',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d1b4389221e871fa433dc7367e120504aedc8a5f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24552,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Royal-High-Road',
        Name: 'Royal High-Road',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d1b4389221e871fa433dc7367e120504aedc8a5f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24411,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Green-Tropics-Pull-Tabs-',
        Name: 'Green Tropics (Pull Tabs)',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/cbeb35970d852f3973a5c849ec923b8596a6e194/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24553,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Taco-Fury-XXXtreme',
        Name: 'Taco Fury XXXtreme',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/cbeb35970d852f3973a5c849ec923b8596a6e194/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24412,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Green-Tropics-3x3-',
        Name: 'Green Tropics (3x3)',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/eba575e811cad43f159e1a5d01175278f62d64a3/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24554,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'No-Fly-Zone',
        Name: 'No Fly Zone',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/eba575e811cad43f159e1a5d01175278f62d64a3/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24413,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Hunter-Totem',
        Name: 'Hunter Totem',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d5e657daa10868dde831de41b309dc51d4fe66d4/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24555,
        ProviderId: 31,
        VendorId: 121,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Babylon',
        Name: 'Babylon',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d5e657daa10868dde831de41b309dc51d4fe66d4/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24414,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Kingly-Illusionist',
        Name: 'Kingly Illusionist',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/25aedb37461a94b00a410814af443831960799bf/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24556,
        ProviderId: 30,
        VendorId: 120,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Hot-Lucky-7',
        Name: 'Hot Lucky 7',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/25aedb37461a94b00a410814af443831960799bf/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24415,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Mexican-Game-3x3-',
        Name: 'Mexican Game (3x3)',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/a3c39a28fb8590d2e5432fb00b788a1cab120630/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24557,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Buster-s-Bones',
        Name: 'Buster’s Bones',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/a3c39a28fb8590d2e5432fb00b788a1cab120630/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24416,
        ProviderId: 26,
        VendorId: 96,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Mexican-Game-Pull-Tabs-',
        Name: 'Mexican Game (Pull Tabs)',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/0abb54462235ead9acd7923c69f4c0fb6da28170/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24558,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'World-of-Lord-Witch-King',
        Name: 'World of Lord Witch King',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/0abb54462235ead9acd7923c69f4c0fb6da28170/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24417,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Mummy-s-Gold',
        Name: 'Mummy’s Gold',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/30f1a7e1e990ff60d86af6ef53501a965eca9cdf/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24559,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Alien-Fruits',
        Name: 'Alien Fruits',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/30f1a7e1e990ff60d86af6ef53501a965eca9cdf/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24418,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Lights-Camera-Cash-',
        Name: 'Lights Camera Cash!',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ab528111004d58bd87ccf62058842886bd9a395e/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24560,
        ProviderId: 30,
        VendorId: 120,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Triple-Cash-Or-CrashTM',
        Name: 'Triple Cash Or CrashTM',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/ab528111004d58bd87ccf62058842886bd9a395e/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24419,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Bombing-Kraken',
        Name: 'Bombing Kraken',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e10a9121ddb679c8d493b25602fb9fca867d01c7/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24561,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Spinata-Grande',
        Name: 'Spinata Grande',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e10a9121ddb679c8d493b25602fb9fca867d01c7/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24420,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Break-Through-Lock-2-Spin',
        Name: 'Break Through Lock 2 Spin',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d103767482b9a9cfe9a6fb2df6c8737fa9008bda/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24562,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Stickers',
        Name: 'Stickers',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d103767482b9a9cfe9a6fb2df6c8737fa9008bda/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24421,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Hot-Coin',
        Name: 'Hot Coin',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/1127a5145d5afeacc6ca925b4f93b266f567cd14/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24563,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Wilderland',
        Name: 'Wilderland',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/1127a5145d5afeacc6ca925b4f93b266f567cd14/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24422,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Jumping-Mr-First',
        Name: 'Jumping Mr. First',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/3f8c37d85064f3ae864e1e0cdc89bdfb76707b31/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24564,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Wild-O-Tron-3000',
        Name: 'Wild-O-Tron 3000',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/3f8c37d85064f3ae864e1e0cdc89bdfb76707b31/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24423,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Legend-of-Sword',
        Name: 'Legend of Sword',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/17d930d39ff064e66563b663e59ad07a718ab9e5/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24565,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Wolf-Cub',
        Name: 'Wolf Cub',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/17d930d39ff064e66563b663e59ad07a718ab9e5/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24424,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Putter-King',
        Name: 'Putter King',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/57e080a0967282bdaba991689f7cdeda71dbae2d/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24566,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Wonders-of-Christmas',
        Name: 'Wonders of Christmas',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/57e080a0967282bdaba991689f7cdeda71dbae2d/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24425,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Merge-Up-',
        Name: 'Merge Up™',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/b1952f7adef1ecaedf493df46b2c816b15218b7f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24567,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Asgardian-Stones',
        Name: 'Asgardian Stones',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/b1952f7adef1ecaedf493df46b2c816b15218b7f/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24426,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Don-t-Eat-the-Candy',
        Name: 'Don’t Eat the Candy',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e87560a7b4cfcc572fd631cf85ab06cc29de4a77/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24568,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Dracula',
        Name: 'Dracula',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/e87560a7b4cfcc572fd631cf85ab06cc29de4a77/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24427,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Fruit-Shop-Frenzy',
        Name: 'Fruit Shop Frenzy',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/459d3b240b5bb4774e560351cdc448f16d4499a3/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24569,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'EggOMatic',
        Name: 'EggOMatic',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/459d3b240b5bb4774e560351cdc448f16d4499a3/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24428,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Scratch-Alpaca-Gold',
        Name: 'Scratch Alpaca Gold',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/3b4d4929fcd33ae7e08721ff32d1f826f8810dfe/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24570,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Guns-N-Roses-Video-Slots',
        Name: "Guns N' Roses Video Slots",
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/3b4d4929fcd33ae7e08721ff32d1f826f8810dfe/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24429,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Scratch-Alpaca-Bronze',
        Name: 'Scratch Alpaca Bronze',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/8011efd73ecb4cc69a10fce738fa1839f576aca9/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24571,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Secrets-of-Atlantis',
        Name: 'Secrets of Atlantis',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/8011efd73ecb4cc69a10fce738fa1839f576aca9/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24430,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Scratch-Alpaca-Silver',
        Name: 'Scratch Alpaca Silver',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/81268a33c260f52b50ccaca45f3f2dd1ee91e72a/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24572,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Jingle-Spin',
        Name: 'Jingle Spin',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/81268a33c260f52b50ccaca45f3f2dd1ee91e72a/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24431,
        ProviderId: 30,
        VendorId: 120,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Golden-Blitz-',
        Name: 'Golden Blitz™',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d520bbe8fd424495b8f706e39d38f42e826ec978/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24573,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Fruit-Blaze',
        Name: 'Fruit Blaze',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/d520bbe8fd424495b8f706e39d38f42e826ec978/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24432,
        ProviderId: 27,
        VendorId: 35,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Monster-Hunt',
        Name: 'Monster Hunt',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/54a22cf3664069565e606d193a3208f862bd5c18/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24574,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Funk-Master',
        Name: 'Funk Master',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/54a22cf3664069565e606d193a3208f862bd5c18/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24433,
        ProviderId: 30,
        VendorId: 120,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'RISE-OF-TRITON-',
        Name: 'RISE OF TRITON™',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/2aadbcbc0f91076ff29ce55ce8d2cfe56625509b/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24575,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Gods-Of-Gold-InfiniReels',
        Name: 'Gods Of Gold: InfiniReels',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/2aadbcbc0f91076ff29ce55ce8d2cfe56625509b/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24434,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Druid-s-Magic',
        Name: 'Druid’s Magic',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/af68598d12f7d015b6f3beddf989423dc806f344/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24576,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Irish-Pot-Luck',
        Name: 'Irish Pot Luck',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/af68598d12f7d015b6f3beddf989423dc806f344/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24435,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Alien-Invasion',
        Name: 'Alien Invasion',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/fd8ecc015b4c5802016e6be20be3511081ca1090/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24577,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Parthenon-Quest-for-Immortality',
        Name: 'Parthenon: Quest for Immortality',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/fd8ecc015b4c5802016e6be20be3511081ca1090/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24436,
        ProviderId: 28,
        VendorId: 116,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Darts-Champion',
        Name: 'Darts Champion',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/2b57a3407af2923ea0cebeeb76e6c682ed4c7e44/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    },
    {
        GameID: 24578,
        ProviderId: 33,
        VendorId: 123,
        VendorName: null,
        IsFavorite: false,
        NameInURL: 'Turn-Your-Fortune',
        Name: 'Turn Your Fortune',
        Img: 'https://d3g531ubdjegcy.cloudfront.net/games-prod/new/2b57a3407af2923ea0cebeeb76e6c682ed4c7e44/Games Catalog image/image.jpg',
        ObjectTypeId: 7,
        BackImage: null,
        LaunchFrameType: 'frame',
        DemoAllowed: true,
        DescriptionUrl: '',
        CustomStyleName: '',
        CustomImgUrl: null,
        Tags: []
    }
];
